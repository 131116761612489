.footer-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: rgba(37, 37, 37, 0.507);
}

.appPromo {
  display: flex;
}
.appLinks {
  display: flex;
}
.stores {
  display: flex;
  flex-direction: column;
  padding: 20px;
  p {
    text-align: left;
    font-size: 22px;
  }
}
.app {
  z-index: 1;
  height: 180px;
  width: 110px;
  align-items: flex-start;
}
.appStore {
  margin: 10px;
  margin-left: 0px;
  width: 140px;
  height: 40px;
}
.playStore {
  width: 140px;
  height: 60px;
}
.footer-p {
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 100%;
  text-align: center;
  color: white;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 0;
  color: #e6e9f0;

  .socials {
    display: flex;
  }
  .socials a {
    font-size: 30px;
    color: #ffffff;
    padding: 15px;
    text-decoration: none;
    justify-content: space-between;
  }
  .socials a:hover {
    color: #007cc7;
  }
  .mission {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 30px;
    h3 {
      padding-bottom: 0.5rem;
      border-bottom: solid thin white;
      color: #007cc7;
    }
    p {
    }
    #email {
      font-size: 22px;
      padding-right: 0.5rem;
    }
    #phone {
      font-size: 22px;
      padding-right: 0.5rem;
    }
    #post {
      font-size: 22px;
      padding-right: 0.5rem;
    }
  }
  .donate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    align-items: center;
    .f-form {
      padding-bottom: 30px;
    }
    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12rem;
      padding: 1rem;
      border-color: white;
      border-radius: 0.5rem;
      font-size: 20px;
      background-color: #08325b;
      color: white;
      transition: 0.5s;
      #paypal {
        margin-left: 0.4rem;
      }
      #podcast {
        margin-left: 0.4rem;
      }
    }
    .submit:hover {
      transform: scale(1.05, 1.05);
      background-color: white;
      color: #08325b;
      border-color: #004e7c;
    }
  }
  .contact {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 40%;
    h3 {
      padding-bottom: 0.5rem;
      border-bottom: solid thin white;
      color: #007cc7;
    }
    p {
      display: flex;
      align-items: center;

      #email {
        font-size: 22px;
        padding-right: 0.5rem;
      }
      #phone {
        font-size: 22px;
        padding-right: 0.5rem;
      }
      #post {
        font-size: 22px;
        padding-right: 0.5rem;
      }
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    .donate {
      padding-top: 10px;
    }
  }
}
@media (max-width: 770px) {
  .footer-container {
    display: flex;
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .mission {
        width: 100%;
      }
      .footer-p {
        display: flex;
      }
      .socials {
        margin-left: 0;
        margin-right: 0;
      }
      .contact {
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 450px) {
  .appLinks {
    display: flex;
    flex-direction: column;
  }
}

.cc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-cc1 {
  // background: url("../Website/vk.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 600px;
  padding: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 450px) {
  .content-cc1 {
    background-size: cover;
    height: 400px;
  }
}

@media (max-width: 300px) {
  .content-cc1 {
    background-size: cover;
    height: 300px;
  }
}

.content-cc2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  h2 {
    color: #203647;
    .verse {
      font-weight: normal;
      font-style: italic;
    }
    text-align: center;
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
  }
  p {
    padding: 30px 0;
    text-align: center;
    color: #203647;
  }
}

.vk-playlist {
  padding-top: 50px;
  background-color: rgba(255, 255, 255, 0.466);
  backdrop-filter: blur(3px);
  width: 100vw;
}

.vk-card {
  display: grid;
  grid-gap: 30px;
  width: 100%;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  margin-bottom: 30px;
  .main-card {
    border: none;
    width: 300px;
    background: none;
    border-radius: 1rem;
    transition: 0.4s;
    z-index: 1;
    .link {
      display: flex;
      flex-direction: row;
      text-decoration: none;
    }
    .images {
      width: 45%;
      border-radius: 1rem;
      height: auto;
      border: none;
      box-shadow: 0px 0px 5px #203647;
    }
    .card-body {
      .title {
        font-size: 14px;
        font-weight: bold;
        color: black;
      }
      .subtitle {
        font-size: 11px;
        color: #5c5f58;
      }
    }
  }
}

.posters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  //width: 100vw;
  img {
    border-radius: 10px;
    margin: 40px;
    width: 400px;
    height: 500px;
    z-index: 1;
  }
}

@media (max-width: 800px) {
  .posters {
    flex-direction: column-reverse;
  }
}

@media (max-width: 410px) {
  .posters {
    align-items: center;
    img {
      width: 90%;
      height: 100%;
    }
  }
}

.content-cc3 {
  padding: 30px 0;
  //padding-left: 6rem;
  //padding-right: 6rem;
  p {
    text-align: center;
    padding: 30px;
    color: #555;
  }
}

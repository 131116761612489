.care {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  width: 100%;
  p {
    text-align: left;
  }
  h2 {
    display: flex;
    //justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    font-family: "Lato", sans-serif;
    border-bottom: solid thin rgb(59, 59, 129);
    #icon {
      padding-left: 30px;
      font-size: 70px;
    }
  }
  img {
    margin-left: 30px;
    border-radius: 10px;
    width: 300px;
    height: 300px;
    z-index: 1;
  }
  .outer {
    display: flex;
    align-items: center;
  }
  .inner {
  }
}

.bb {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bCounseling {
  display: flex;
  align-items: center;
  padding: 30px 0;
  font-family: "Lato", sans-serif;
  p {
    padding: 20px 0;
    font-family: "Lora", sans-serif;
  }
  li {
    font-family: "Lora", sans-serif;
  }
}

.marriages {
  //flex-direction: column;
  background-color: rgba(255, 255, 255, 0.466);
  backdrop-filter: blur(3px);
  width: 100vw;
  padding: 30px 0;
  //justify-content: center;
  p {
    padding: 20px 0;
    font-family: "Lora", sans-serif;
  }
  li {
    font-family: "Lora", sans-serif;
  }
}

.births {
  display: flex;
  align-items: center;
  padding: 30px 0;

  p {
    padding: 20px 0;
    font-family: "Lora", sans-serif;
  }
  li {
    font-family: "Lora", sans-serif;
  }
}

.seniors {
  background-color: rgba(255, 255, 255, 0.466);
  backdrop-filter: blur(3px);
  width: 100vw;
  padding: 30px 0;

  p {
    padding: 20px 0;
    font-family: "Lora", sans-serif;
  }
  li {
    font-family: "Lora", sans-serif;
  }
}

.deaths {
  padding: 30px 0;
  .verse {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    font-weight: bold;
  }
  p {
    padding: 20px 0;
    font-family: "Lora", sans-serif;
  }
  li {
    font-family: "Lora", sans-serif;
  }
}

@media (max-width: 800px) {
  .care {
    img {
      display: none;
    }
  }
}

.class {
  width: 100vw;
  // height:
  h1 {
    display: flex;
    z-index: 1;
    justify-content: center;
    align-items: center;
    padding-top: 15%;
    padding-bottom: 15%;
    color: #ffffff;
  }
}

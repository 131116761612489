.bible-study {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fake {
  display: none;
}
.content-bible-1 {
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 600px;
  padding: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.content-bible-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  p {
    text-align: center;
    font-weight: 500;
    color: #555;
    .verse {
      font-weight: bold;
      font-style: italic;
      font-family: "Times New Roman", Times, serif;
    }
  }
}

.content-bible-3 {
  padding: 30px 0;
  h3 {
    text-align: center;
    color: #203647;
    font-weight: bold;
    .verse {
      font-weight: 500;
      font-style: italic;
      font-family: "Times New Roman", Times, serif;
    }
  }
}

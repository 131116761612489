.navbar {
  z-index: 1;
  position: relative;
  margin: 20px 0 !important;
  // top: 0;
  // left: 0;
  width: 98% !important;
  height: 62px;
  background-color: transparent;
}

.navbar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
}

.next {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar__logo {
  height: 62px;
  .logo {
    img {
      width: 260px;
      overflow-y: hidden;
    }
  }
}

@media (max-width: 360px) {
  .navbar {
    .container {
      padding: 0;
    }
  }
  .navbar__navigation {
    padding: 0;
  }
}

.drawer-toggle {
  display: flex;
  margin-left: 20px;
}

.navbar_navigation-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  width: 100%;
  .items {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    list-style: none;
    //margin-left: 3rem;
    letter-spacing: 2px;
    // font-size: large;
    padding-top: 0;
    padding-bottom: 0;
    // padding: 2rem;
    // display: flex;
    color: #fff;
  }
}

.dropdown {
  text-decoration: none;
  list-style: none;
  //margin-left: 3rem;
  font-size: large;
  padding-top: 0;
  padding-bottom: 0;
  padding: 2rem;
  display: flex;
  color: #444;
  .menu {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .items {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    color: #5c5f58;
    text-decoration: none;
  }
  .items:hover,
  .items:active {
    color: rgb(59, 59, 129);
  }
}
.dropdown:hover,
.dropdown:active {
  color: rgb(59, 59, 129);
  cursor: pointer;
}

@media (max-width: 1020px) {
  .navbar_navigation-items {
    opacity: 0;
    transition: opacity 1s ease-out;
    //display: none;
  }
  .next {
    .donate {
      display: none;
      transition: opacity 1s ease-out;
    }
  }
}
@media (max-width: 1000px) {
  .navbar_navigation-items {
    display: none;
  }
  .donate-nav {
    display: none;
  }
}

.watch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.watch-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  h1 {
    font-weight: bolder;
  }
}

.feature {
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  width: 100vw;
  .feature-video {
    width: 85%;
    height: 720px;
    border-radius: 10px;
    border: none;
  }
}
.display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.466);
  background: #08325b5d;
  backdrop-filter: blur(3px);
  box-shadow: 0px 0px 10px #08325b;
  width: 100vw;
  h1 {
    width: 100%;
  }
}

.header-container {
  height: 30px;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;
  margin-bottom: 60px;
  .title-button1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    color: #08325b;
    box-shadow: 0px 0px 10px #5c5f58;
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
    transition: 0.4s;
    font-weight: bold;
    font-size: 24px;
    #title-icon {
      transition: 0.4s;
      font-size: 45px;
      padding-bottom: 10px;
      color: #08325b;
    }
  }
  .title-button1:hover {
    transform: scale(1.1, 1.1);
    background-color: #08325b6c;
    backdrop-filter: blur(3px);
    color: #ffffff;
    #title-icon {
      color: #ffffff;
    }
  }
  .title-button1:disabled {
    background-color: #08325b6c;
    backdrop-filter: blur(3px);
    transform: none;
    color: rgba(255, 0, 0, 0.507);

    #title-icon {
      color: red;
    }
  }
  .title-button2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    color: #08325b;
    box-shadow: 0px 0px 10px #5c5f58;
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 40px;
    margin-right: 40px;
    transition: 0.4s;
    font-weight: bold;
    font-size: 24px;
    #title-icon {
      transition: 0.4s;
      font-size: 45px;
      padding-bottom: 10px;
      color: #08325b;
    }
  }
  .title-button2:hover {
    transform: scale(1.1, 1.1);
    background-color: #08325b6c;

    backdrop-filter: blur(3px);
    color: #ffffff;
    #title-icon {
      color: #ffffff;
    }
  }
  .title-button2:disabled {
    background-color: #08325b6c;
    backdrop-filter: blur(3px);
    transform: none;
    color: rgba(255, 0, 0, 0.507);
    #title-icon {
      color: red;
    }
  }
  .title-button3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    color: #08325b;
    box-shadow: 0px 0px 10px #5c5f58;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 40px;
    margin-left: 40px;
    transition: 0.4s;
    font-weight: bold;
    font-size: 24px;
    #title-icon {
      transition: 0.4s;
      font-size: 45px;
      padding-bottom: 10px;
      color: #08325b;
    }
  }
  .title-button3:hover {
    transform: scale(1.1, 1.1);
    background-color: #08325b6c;
    backdrop-filter: blur(3px);
    color: #ffffff;
    #title-icon {
      color: #ffffff;
    }
  }
  .title-button3:disabled {
    background-color: #08325b6c;
    backdrop-filter: blur(3px);
    transform: none;
    color: rgba(255, 0, 0, 0.507);

    #title-icon {
      color: red;
    }
  }
}

@media (max-width: 640px) {
  .title-container {
    flex-direction: column;
    .title-button1 {
      margin-left: 0;
      margin-right: 0;
    }
    .title-button2 {
      margin-right: 0;
    }
    .title-button3 {
      margin-left: 0;
    }
  }
}

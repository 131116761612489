.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // z-index: -1;
}

.content-home1 {
  width: 100vw;
}

.live {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 40px;
  h3 {
    text-align: left;
  }
}
.secondary {
  // display: flex;
  //flex-direction: row;
  //padding-top: 2rem;
  padding-bottom: 30px;
  width: 100%;

  .content-home2 {
    padding: 2rem;

    p {
      text-align: left;
    }
  }

  .side-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  .google-map {
    padding-right: 15px;
    // .map {
    //   width: 30rem;
    //   height: 20rem;
    //   border-radius: 1rem;
    //   box-shadow: 0px 0px 5px #203647;
    // }
  }
  .video-landing {
    padding-left: 15px;
    // .player {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   width: 30rem;
    //   height: 20rem;
    //   border-radius: 1rem;
    //   font-size: 44px;
    //   font-weight: bold;
    //   font-family: "Lato", sans-serif;
    //   color: white;
    //   border: none;
    //   background-color: rgba(255, 255, 255, 0.15);
    //   backdrop-filter: blur(3px);
    //   box-shadow: 0px 0px 5px #203647;
    // }
    #tube {
      font-size: 55px;
      margin: 10px;
      color: red;
    }
  }
}

// @media (max-width: 900px) {
//   .secondary {
//     display: flex;
//     flex-direction: column;
//   }
// }

@media (max-width: 930px) {
  .secondary {
    .side-bar {
      flex-direction: column;
      padding: 0;
    }
    .google-map {
      padding-right: 0;
      padding-bottom: 30px;
      .map {
        width: 100%;
        height: 100%;
      }
    }
  }
}

body {
  margin: 0;
  position: relative;
  //height: 100%;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: #f8f9fa;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

code {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#root {
  height: 100vh;
}
.side-drawer {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.685);
  backdrop-filter: blur(3px);
  //box-shadow: 2px 0px 5px black;
  position: fixed;
  top: 0;
  right: 0;
  width: 16rem;
  z-index: 200;
  opacity: 0.3;
  transform: translateX(100%);
  transition: opacity 0.2s ease-out, transform 0.2s ease-in-out;
  #close {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    padding-right: 2rem;
    font-size: 5rem;
    transition: 0.1s;
  }
  #close:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
    color: #08325b;
  }
  .navbar-nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    margin: 80px 0;
    .nav-item {
      display: flex;
      //justify-content: center;
      align-items: center;
      padding: 1rem 15px;
      color: #444;
      text-decoration: none;
      font-size: 1.2rem;
      //border-bottom: solid thin rgb(163, 163, 163);
      border: opacity 0.5;
      width: 100%;
      #icons {
        width: 24px;
        margin-right: 30px;
        font-size: 24px;
        color: #444;
      }
    }
    .nav-item:hover,
    .nav-item:active {
      color: #08325b;
    }
  }
  .dropdown-side {
    display: flex;
    //justify-content: center;
    padding: 1rem 15px;
    color: #444;
    text-decoration: none;
    font-size: 1.2rem;
    //border-bottom: solid thin rgb(163, 163, 163);
    border: opacity 0.5;
    width: 100%;
    .menu-side {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      #icons {
        padding-right: 1rem;
        font-size: 2.5rem;
        color: #444;
      }
    }
    .nav-item {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      color: #5c5f58;
      text-decoration: none;
    }
    .nav-item:hover,
    .nav-item:active {
      color: rgb(59, 59, 129);
    }
  }
  .dropdown:hover,
  .dropdown:active {
    color: rgb(66, 66, 133);
    cursor: pointer;
  }
}
.side-drawer.open {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.1s ease-in, transform 0.1s ease-in-out;
}

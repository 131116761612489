.give-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.give {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background: url("../Website/giving.png");
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 500px;
  padding: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.content-1 {
  width: 100vw;
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    color: black;
    h1 {
      margin-bottom: 2rem;
      font-weight: bold;
    }
    p {
      margin-bottom: 40px;
      font-weight: bold;
      .verse {
        font-weight: normal;
        font-style: italic;
      }
    }
    .donate {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      .submit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12rem;
        padding: 1rem;
        border-color: none;
        border: none;
        border-radius: 2rem;
        font-size: 26px;
        background-color: rgba(51, 51, 45, 0.507);
        color: white;
        transition: 0.5s;
        #paypal {
          margin-left: 0.4rem;
        }
      }
      .submit:hover {
        transform: scale(1.05, 1.05);
        background-color: white;
        border: groove;
        color: #004e7c;
        border-color: #004e7c;
      }
    }
  }
}

.content-2 {
  // background-color: white;
  // padding: 30px;
  // width: 100vw;
  // box-shadow: 0px 0px 10px white;
  .context {
    display: flex;
    flex-direction: column;
    //padding: 10rem;
    //justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;
    margin-bottom: 30px;
    p {
      text-align: center;
    }

    h6 {
      #bible {
        color: #007cc7;
        margin-bottom: 30px;
        font-size: 40px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
      margin-bottom: 40px;
      color: #555;
    }
  }
}

.content-3 {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .charity {
    //display: flex;
    //flex-direction: row;
    //align-items: center;
    width: 50%;
    padding: 30px;
    p {
      text-align: center;
      font-weight: bold;
      color: #2c485e;
    }
  }
  .ways-to-give {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //padding: 30px;
    .first {
      display: flex;
      flex-direction: row;
      justify-content: center;
      //padding-right: 5px;
      //align-items: center;
      .place {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
        align-items: center;
        h4 {
          font-weight: bold;
          text-align: center;
          color: #2c485e;
        }
        p {
          color: #5c5f58;
        }
        #heart {
          color: #007cc7;
          margin-bottom: 2rem;
          font-size: 40px;
        }
        #apple {
          color: #007cc7;
          // margin-top: 2rem;
          margin-bottom: 2rem;
          font-size: 40px;
        }
      }
    }
    .second {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 30px;
      .place {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 45%;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(3px);
        align-items: center;
        img {
          z-index: 1;
          width: 90%;
        }
      }
    }

    @media (max-width: 540px) {
      .second {
        .place {
          width: 80%;
        }
      }
    }
    .third {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .place {
        display: flex;
        flex-direction: column;
        padding: 15px;
        align-items: center;
        h4 {
          font-weight: bold;
          text-align: center;
          color: #2c485e;
        }
        p {
          color: #5c5f58;
        }
        #pay {
          color: #007cc7;
          //margin-top: 2rem;
          margin-bottom: 2rem;
          font-size: 40px;
        }
        #money {
          color: #007cc7;
          margin-bottom: 2rem;
          font-size: 40px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .content-3 {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .charity {
      width: 100%;
    }
  }
}

.content-4 {
  //background-color: rgb(245, 245, 245);
  display: flex;
  justify-content: center;
  padding: 30px;
  video {
    border-radius: 10px;
    width: 90%;
  }
}

@media (max-width: 520px) {
  .content-2 {
    .context {
      padding: 6rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  .content-3 {
    .ways-to-give {
      .first {
        flex-direction: column;
      }
      .third {
        flex-direction: column;
      }
    }
  }
}

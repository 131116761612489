@import "./bellsOfRevival.scss";
@import "./bibleStudy.scss";
@import "./care.scss";
@import "./childrensChurch.scss";
@import "./drawerToggle.scss";
@import "./footer.scss";
@import "./giving.scss";
@import "./landingPage.scss";
@import "./legal.scss";
@import "./navBar.scss";
@import "./notFound.scss";
@import "./player.scss";
@import "./sermons.scss";
@import "./sideDrawer.scss";
@import "./watch.scss";

h1,
h2,
h3 {
  letter-spacing: 3px;
}

.rel-pos {
  position: relative;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.no-over {
  overflow: hidden;
}

.videoWrapper {
  padding-bottom: 56.25%;
  padding-top: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contain {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
}

.main-contain {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  max-width: 1080px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .contain {
    min-width: 1080px;
  }
}

@media (min-width: 992px) {
  .contain {
    min-width: 960px;
  }
}

@media (min-width: 768px) {
  .contain {
    min-width: 720px;
  }
}

@media (min-width: 576px) {
  .contain {
    min-width: 540px;
  }
}

.movie::-webkit-scrollbar {
  display: none;
}

.movie {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#root {
  // width: 100%;
  background: url("../Website/mobile.png");
  background-size: contain;
  background-attachment: fixed;
  position: absolute;
}

@media (min-width: 446px) {
  #root {
    background: fixed url("../Website/home-banner.jpg");
    background-size: cover;
  }
}

p {
  font-weight: 400 !important;
}

.site-text {
  font-size: 16px;
  font-weight: 400 !important;
  text-justify: inter-word;
}

.verse {
  font-size: 16px;
  font-family: "Times New Roman", Times, serif !important;
  font-style: italic !important;
}

.site-header {
  color: #2c485e;
  font-weight: bold;
  letter-spacing: 2px;
}

.main-header {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 4vw;
}

.service-text {
  font-size: 2vw;
}

.service-middle {
  font-size: 1.5vw;
}

@media (max-width: 1000px) {
  .service-text {
    font-size: 2.5vw;
  }

  .service-middle {
    font-size: 2vw;
  }
}

@media (max-width: 740px) {
  .service-text {
    font-size: 3vw;
  }

  .service-middle {
    font-size: 2.5vw;
  }
}

@media (max-width: 1600px) {
  .main-header {
    font-size: 4vw;
  }
}

@media (max-width: 950px) {
  .main-header {
    font-size: 3.5vw;
  }
}
@media (max-width: 560px) {
  .main-header {
    font-size: 4vw;
  }
}

.visit {
  width: 100vw;
  z-index: 1;
}

@media (max-width: 1030px) {
  .visit {
    margin-top: 22% !important;
    margin-bottom: 22% !important;
  }
}

@media (max-width: 450px) {
  .visit {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.overlay::before {
  z-index: 2;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.664) 99%,
    rgba(0, 0, 0, 0.753) 100%
  );
}

a {
  text-decoration: none !important;
}

.contained {
  margin-top: 200px;
  margin-bottom: 200px;
  text-align: center;
}

#closeModal {
  display: flex;
  color: #ffffff;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 30px;
  transition: 0.1s;
}
#closeModal:hover {
  cursor: pointer;
  transform: scale(1.1, 1.1);
  color: #08325b;
}

.btn1 {
  background-color: #08325b !important;
  font-weight: 700;
}

.thanks {
  display: flex;
  width: 100vw;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    color: #ffffff;
    padding-top: 80px;
    padding-bottom: 20px;
    z-index: 1;
    font-size: 60px;
  }
  h2 {
    text-align: center;
    color: #808080;
    margin-bottom: 60px;
    border-bottom: solid thin rgb(59, 59, 129);
    width: 100%;
    z-index: 1;
    padding-bottom: 15px;
  }
}

.pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.anticon {
  color: #ffffff !important;
}

.ant-pagination-item-active {
  background: #808080 !important;
}

.ant-pagination-item {
  color: #ffffff !important;
}

.loading {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
  background: #ffffff;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  // font-size: 60px;
  // animation: reveal 3000ms ease-in-out forwards 200ms,
  //   glow 2500ms linear infinite 2000ms;

  @keyframes reveal {
    80% {
      letter-spacing: 8px;
    }
    100% {
      background-size: 300% 300%;
    }
  }
  @keyframes glow {
    40% {
      text-shadow: 0 0 8px #999;
    }
  }
}

p {
  line-height: 1.5 !important;
}

.wash {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 0;
}

.top-banner {
  img {
    width: 100%;
    // opacity: 0.6;
  }
  min-height: 450px;
  width: 100%;
  // padding-bottom: 40px;
  position: relative;
  background-size: cover;
  // background-image: url("../Website/home-banner.jpg");
}

.col-tight {
  padding: 0 !important;
}

.top-banner-container {
  text-align: center;
  z-index: 1;
  position: relative;
  padding: 100px 0 60px;
  color: #ffffff;
}

.banner-title {
  font-weight: 600;
  font-size: 60px;
}

.map-content {
  // text-align: center;
  position: relative !important;
  z-index: 1;
  color: #fff;
}

.image-section__textcenter {
  z-index: 1;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding: 40px;
}

@media (max-width: 990px) {
  .image-section__textcenter {
    padding: 50px 0;
    margin: 0 auto 60px;
    width: 90%;
  }
}

.image-section__textleft {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
}

@media (max-width: 990px) {
  .image-section__textleft {
    padding: 50px 0;
    margin: 0 auto 60px;
    width: 90%;
  }
}

.item-section .row {
  align-items: center;
}

.section-top {
  padding: 0;
}

.backing {
  background: #808080;
}

@media (max-width: 767px) {
  .text .backing-half {
    width: 100% !important;
  }
}

.text {
  position: relative;
  min-height: 200px;
}

.text .text-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  z-index: 1;
  position: relative;
  padding: 60px 40px;
}

.text .text-content .aTitle {
  color: #ffffff;
  // font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.text .text-content h4 {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.text .text-content p {
  color: #ffffff;
}

.text .text-content--one {
  padding-top: 90px;
}

.appLink {
  border-radius: 16px;
  border-color: #2c485e;
  transition: 0.8s;
  a {
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: #08325b;
    font-size: larger;
    transition: 0.8s;
    margin: 10px;
  }
}

#appStore {
  color: #08325b;
  font-size: x-large;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.8s;
}

.appLink:hover {
  background-color: #08325b;
  border-color: #ffffff;
  a {
    color: #ffffff;
    #appStore {
      color: #ffffff;
    }
  }
}

.text .backing-half {
  background: #08325b;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  position: absolute;
}

.donate {
  display: flex;
  //padding-top: 2rem;
  align-items: center;
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 3rem;
    padding: 1rem;
    border-color: #08325b;
    border-radius: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    background-color: #fff;
    color: #08325b;
    transition: 0.5s;
    #paypal {
      margin-left: 0.4rem;
      font-size: 20px;
    }
  }
  .submit:hover {
    transform: scale(1.05, 1.05);
    background-color: #08325b;
    color: #ffffff;
    border-color: #ffffff;
  }
}

.feature-text {
  margin-top: 30px;
  width: 85%;
}

.sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activities {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  background: #08325bbb;
  margin: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
  z-index: 1;
}

.col-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  margin: 20px;
  max-width: 300px !important;
  min-width: 275px !important;
  transition: 0.4s;
}

.item:hover {
  transform: scale(1.08) !important;
}

.item-img {
  border-radius: 12px;
  width: 100%;
}

.donate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .f-form {
    padding-bottom: 30px;
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    padding: 1rem;
    border-color: white;
    border-radius: 0.5rem;
    font-size: 20px;
    background-color: #08325b;
    color: white;
    transition: 0.5s;
    #paypal {
      margin-left: 0.4rem;
    }
  }
  .submit:hover {
    transform: scale(1.05, 1.05);
    background-color: white;
    color: #08325b;
    border-color: #004e7c;
  }
}
.PhoneInputCountryIcon {
  display: none;
  width: 40px !important;
  margin-right: 20px !important;
  margin-bottom: 0px !important;
}

.PhoneInputCountry {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.PhoneInput {
  margin-top: 20px;
}

.sermon-playlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  // background-color: white;
  padding-bottom: 40px;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
  padding-bottom: 40px;
  p {
    text-align: center;
  }

  #youtube {
    font-size: 36px;
  }
}

.sermon-card {
  display: flex;
  width: 100%;
  grid-gap: 30px;
  justify-content: center;
  align-items: center;
  //grid-auto-rows: 1fr;
  // grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));s
  margin-bottom: 30px;
  .main-card {
    border: none;
    margin: 0 !important;
    width: 450px;
    //height: 200px;
    background: none;
    border-radius: 1rem;
    transition: 0.4s;
    z-index: 1;
    .link {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      .images {
        width: 65%;
        //height: 100px;
        border-radius: 1rem;
        height: auto;
        border: none;
        box-shadow: 0px 0px 5px #203647;
      }
      .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        padding-left: 10px !important;
        padding: 0;
        .title {
          font-size: 16px;
          color: #ffffff;

          font-weight: bold;
        }
        .subtitle {
          font-size: 14px;
          font-weight: 600;
          color: #5c5f58;
        }
      }
    }
  }
}

// @media (max-width: 500px) {
//   .sermon-card {
//     .main-card {
//       width: 300px !important;
//       .link {
//         .images {
//           width: 50%;
//         }
//       }
//     }
//   }
// }

.main-card:hover {
  transform: scale(1.02, 1.02);
}

.pagination {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

// .navbar {
//   background-color: #555;
// }

.player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(
    circle at center center,
    rgb(71, 71, 71),
    rgb(8, 8, 8)
  );
  width: 100vw;
  margin-bottom: 40px;
}

.frame {
  height: 620px;
}

.player-video {
  margin-top: 4rem;
  width: 100%;
  height: 90%;
  border: none;
}

// @media (max-width: 900px) {
//   .player-video {
//     width: 30rem;
//   }
//}

.display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}

.player-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 30px;
}

.info {
  h1 {
    color: white;
  }
  p {
    color: #999;
  }
}

.links {
  .btn {
    color: white;
    font-size: 2rem;
  }
}

.pop {
  display: flex;
  color: rgba(0, 0, 0, 0.664);
}

.play-card {
  display: grid;
  grid-gap: 30px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  margin-bottom: 30px;

  .play {
    border: none;
    background: none;
    border-radius: 10px;
    transition: 0.4s;
    .link {
      display: flex;
      flex-direction: row;
      text-decoration: none;
    }
    .img {
      width: 50%;
      border-radius: 10px;
      height: auto;
      border: none;
      box-shadow: 0px 0px 5px #203647;
    }
    .card-body {
      .title {
        font-weight: 800;
        color: #5c5f58;
      }
      .subtitle {
        color: #5c5f58;
      }
    }
  }
  .play:hover {
    transform: scale(1.05, 1.05);
  }
}

.legal {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 20%;
  padding-right: 20%;
  p {
    text-align: left;
  }
  h2 {
    text-align: left;
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #2c485e;
  }

  h4 {
    padding-bottom: 40px;
    color: #5c5f58;
  }
  .red {
    color: #932432;
  }
  ul {
    padding-top: 20px;
    li {
      text-align: left;
      color: #5c5f58;
    }
  }
  .logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
    img {
      width: 20rem;
      padding: 2rem;
      margin-top: 7.5rem;
      margin-bottom: 10rem;
    }
  }
}

@media (max-width: 540px) {
  .legal {
    padding-left: 30px;
    padding-right: 30px;
    .logos {
      img {
        width: 12rem;
      }
    }
  }
}

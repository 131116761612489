.toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1.5rem;
  width: 1.7rem;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
}

.toggle:focus {
  outline: none;
}

.toggle__line {
  width: 1.5rem;
  height: 2px;
  background: #ffffff;
}

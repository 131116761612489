.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.content-bells-1 {
  display: flex;
  padding: 40px;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: column;
  align-items: center;
  .bell {
    height: 300px;
    width: 300px;
    padding-bottom: 25px;
    z-index: 1;
  }
  h3 {
    text-align: center;
    font-weight: 500;
    color: #2c485e;
  }
}

.content-bells-2 {
  padding: 30px 0;
  h2 {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: solid thin rgb(59, 59, 129);
    color: #2c485e;
  }

  p {
    text-align: left;
    display: flex;
    align-items: center;
    #bible {
      color: #007cc7;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-right: 25px;
      font-size: 24px;
    }
  }
}

.content-bells-3 {
  padding: 30px 0;
  h2 {
    padding: 20px 0;
    border-bottom: solid thin rgb(59, 59, 129);
    color: #2c485e;
  }
  p {
    display: flex;
    text-align: left;
    align-items: center;
    #bible {
      color: #007cc7;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-right: 25px;
      font-size: 24px;
    }
  }
}
